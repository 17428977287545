import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { createShadow } from '../styles/helpers/createShadow'
import { v } from '../styles/variables'
import { IconButton } from '../ui/buttons'
import { DIV, H2 } from '../ui/tags'
import { stopPropagation } from '../utils'

const BaseModal = ({ title, children, isOpen, open, close, ...props }) =>
  isOpen
    ? ReactDOM.createPortal(
        <div {...props} onClick={close}>
          <div className="main" onClick={stopPropagation}>
            <DIV verticalCenter bg={v.c.green.dark} pt05 pr025 pb05 pl075>
              <H2 color="white" myAuto>
                {title}
              </H2>
              <IconButton onClick={close}>close</IconButton>
            </DIV>
            <div className="content">{children}</div>
          </div>
        </div>,
        document.body
      )
    : null

export const Modal = styled(BaseModal)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: flex;

  .main {
    background: white;
    min-width: 300px;
    max-width: 500px;
    margin: auto;
    border-radius: ${v.borderRadius};
    box-shadow: ${v.shadowLargest};
  }

  ${IconButton} {
    color: white;
    margin: auto 0 auto auto;
    font-size: ${v.s2};
    border-radius: 50%;
    padding: ${v.s025};

    &:hover {
      background: ${v.c.darken[15]};
    }

    &:active {
      background: ${v.c.darken[25]};
    }
  }

  .content {
    padding: ${v.s2};
  }
`

export class ModalManager extends Component {
  state = { isOpen: false }

  render() {
    return this.props.render({
      isOpen: this.state.isOpen,
      open: () => this.setState({ isOpen: true }),
      close: () => this.setState({ isOpen: false }),
    })
  }
}

import React, { Component } from 'react'
import styled from 'styled-components'
import { Root } from '../components/Root'
import { StripeCheckout } from '../components/StripeCheckout'
import { emails } from '../emails'
import { v } from '../styles/variables'
import { Button } from '../ui/buttons'
import { Card } from '../ui/cards'
import { DIV, P } from '../ui/tags'
import { url } from '../urls'

class Donate extends Component {
  state = { hasWindow: false }

  componentDidMount() {
    this.setState({ hasWindow: true })
  }

  render() {
    return (
      <Root>
        {this.state.hasWindow &&
          window.location.hash === '#success' && (
            <Thanks mb1>Thanks for your donation!</Thanks>
          )}
        <h2>Donate</h2>
        <Card>
          <p>
            Donations are primarily used to provide Big Books to the alcoholics
            at the facilities we serve.
          </p>
          <P>
            The primary way to donate to H&amp;I is via the &quot;Green
            Cans&quot; that are passed around at meetings. If you&apos;re
            homegroup doesn&apos;t currently pass around a green can, please
            contact us to get one! Alternatively, some groups simply donate a
            percentage of what they receive via the 7th tradition.
          </P>
          <DIV flexTop mt2>
            <div>
              <StripeCheckout />
              <GreenCan to={emails.contact('How do I get a green can?')} mt2>
                Get a Green Can
              </GreenCan>
            </div>

            <MailCheck>
              <p>
                <strong>Mail us a check</strong>
                <br />
                Make checks out to &quot;PNWHI&quot; and mail to:
              </p>
              <p>
                Pacific NW Hospitals &amp; Institutions
                <br />
                P.O. Box 6751
                <br />
                Portland, OR 97228
              </p>
            </MailCheck>
          </DIV>
        </Card>
      </Root>
    )
  }
}

const Thanks = styled(Card)`
  padding: ${v.s1};
  background: ${v.c.green[100]};
  border: 2px solid ${v.c.green[500]};
  color: ${v.c.green[900]};
`
const MailCheck = styled.div`
  border-left: 1px solid ${v.c.gray[100]};
  margin-left: ${v.s2};
  padding-left: ${v.s2};
`
const GreenCan = styled(Button)`
  width: 100%;
`

export default Donate

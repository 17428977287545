import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import AutosizeInput from 'react-input-autosize'
import { skus } from '../skus'
import { v } from '../styles/variables'
import { Button } from '../ui/buttons'
import { DIV } from '../ui/tags'
import { Modal, ModalManager } from './Modal'

export class StripeCheckout extends Component {
  state = { amount: '', error: null }

  onSubmit = e => {
    e.preventDefault()

    if (this.state.amount < 5) {
      this.setState({ error: '$5 is the minimum donation amount.' })
      return
    }

    const sku = skus[this.state.amount]
    if (!sku) {
      this.setState({ error: '$500 is the maximum donation amount.' })
      return
    }

    const stripe = Stripe('pk_live_OQi8OqVJaXMM8S7a4TfHIbXd', {
      betas: ['checkout_beta_4'],
    })

    // When the customer clicks on the button, redirect
    // them to Checkout.
    stripe
      .redirectToCheckout({
        items: [{ sku, quantity: 1 }],

        // Note that it is not guaranteed your customers will be redirected to this
        // URL *100%* of the time, it's possible that they could e.g. close the
        // tab between form submission and the redirect.
        successUrl: 'https://pnwhi.org/donate#success',
        cancelUrl: 'https://pnwhi.org/donate',
      })
      .then(function({ error }) {
        if (error) this.setState({ error })
      })
  }

  updateAmount = e =>
    this.setState({
      amount: e.target.value.replace(/[^0-9]+/g, ''),
      error: null,
    })

  render() {
    return (
      <ModalManager
        render={modalProps => (
          <Fragment>
            <div>
              <Button onClick={modalProps.open}>Donate Online Now</Button>
            </div>
            <Modal title="Donate" {...modalProps}>
              <center>
                How much would you like to donate?
                <form onSubmit={this.onSubmit}>
                  <InputContainer mt2 flex>
                    <div>$</div>
                    <AutosizeInput
                      type="text"
                      placeholder="50"
                      onChange={this.updateAmount}
                      value={this.state.amount}
                      style={{
                        fontSize: v.s4,
                        textAlign: 'right',
                        maxWidth: 417,
                      }}
                      autoFocus
                    />
                  </InputContainer>
                  <Error>{this.state.error}</Error>
                  <Button type="submit" mt2>
                    Donate
                  </Button>
                </form>
              </center>
            </Modal>
          </Fragment>
        )}
      />
    )
  }
}

const InputContainer = styled(DIV)`
  font-size: ${v.s4};
  justify-content: center;
  color: ${v.c.gray[500]};
`
const Error = styled.div`
  color: ${v.c.red.main};
`
